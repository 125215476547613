import imagen from './imag/prog.jpg';
import Formulario from './components/formulario'
import Footer from './components/footer'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Nav,Image } from 'react-bootstrap';

// sintaxis jsx
function App() {
  return (
    <div className="App">
      <Nav
      classname="bg-success">

<Image src="https://i.pravatar.cc/50" roundedCircle />
 
  <Nav.Item>
    <Nav.Link href="/"classname="text-white">Inicio</Nav.Link>
  </Nav.Item>

  <Nav.Item>
    <Nav.Link href="/"classname="text-white">Nosotros</Nav.Link>
  </Nav.Item>
  
</Nav>
      <header className="App-header">
        <img src={imagen} className="App-logo" alt="logo" />
        <p>
          Carlos Rodriguez 210986
    
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <Formulario />
      <Footer />
      <Button variant="success">
        Este es un boton diferente
      </Button>

    </div>
  );
}

export default App;
