
import { Form} from 'react-bootstrap';
import {useState} from "react"
import axios from "axios"
const Formulario=()=>{


const[signo, setSigno]=useState()
const[dia,setDia]=useState();
const [nacimiento, setNacimiento]=useState("");

async function clickUsuario(e){
    e.preventDefault();

    const respuesta= await axios.post("https://aztro.sameerkumar.website/?sign="+signo+"&day"+dia )
    console.log(respuesta.data)
    setNacimiento(respuesta.data.date_range);

}

return (
    <div>
        <form onSubmit={clickUsuario}>
            <label>Signo</label>
            <Form.Control onChange={(e)=>{
                
                setSigno(e.target.value)
            }}  placeholder="Tauro..." />
            <p className="text-muted">
            Ingresa tu signo zodiacal
            </p>
        <label>Día</label>
            <select onChange={(e)=>{

                setDia(e.target.value)
            }} >
                <option value="today">today</option>
                <option value="tomorrow">tomorrow </option>
                <option value="yesterday">yesterday</option>
            </select>
        <button type="submit" variant="primary">
            Consultar
        </button>
        </form>
       <div>
           Fecha de nacimiento: {nacimiento}
       </div>
    </div>
)
}

export default Formulario;
            